.right-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
  }
  
  .profile-container {
    position: relative;
    cursor: pointer;
  }
  
  .profile-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  
  .profile-dropdown {
    position: absolute;
    width: 200px;
    top: 30px;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .profile-dropdown p {
    margin: 0;
    padding-bottom: 10px;
  }
  
  .profile-dropdown button {
    padding: 5px 10px;
    background-color: #f00;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .login-btn {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  