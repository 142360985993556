*{
  margin: 0;padding: 0;box-sizing: border-box;
  font-family: "Lexend", system-ui;

}
ul , p{
  margin-bottom: 0;
}
.login-banner{
  min-height: 100vh;
  background-image: url(./Assets/banner.png);
  padding: 50px;
  background-size: cover;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 20px;

}
.login-banner-left{
  width: 50%;

}
.login-banner-left2{
  position: sticky;
  height: 100vh;
  padding-top: 100px;
  top: 0;

}
.login-banner-right{
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.login-banner-left img{}
.login-banner-left h2{
  font-family: "Lexend", system-ui;
  font-size: 40px;
  font-weight: 500;
}
.login-banner-left h3{
  font-family: "Lexend", system-ui;
  font-size: 30px;
  font-weight: 400;
  margin-top: 10px;
}
.login-banner-left p{
  font-family: "Lexend", system-ui;
  font-size: 16px;
  font-weight: 400;
}
.login-banner-left p span{
  color: rgba(115, 90, 229, 1);
}
.login-banner-vec{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.login-form{
  background-color: white;
  border-radius: 30px;
  width: 80%;
  padding: 50px;
}
.login-form input{
  width: 100%;
  background-color: rgba(240, 239, 255, 1);
  height: 62px;
  border-radius: 8px;
  border: none;
  padding-left: 15px;

}
.login-form select{
  width: 100%;
  background-color: rgba(240, 239, 255, 1);
  height: 62px;
  border-radius: 8px;
  border: none;
  padding-left: 15px;

}
.login-form button{
  width: 100%;
  background-color: rgba(115, 90, 229, 1);
  height: 59px;
  color: white;
  font-family: "Lexend", system-ui;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  border: none;
}
.login-form p{
  text-align: center;
  color: rgba(181, 181, 181, 1);
  font-family: "Lexend", system-ui;
  font-size: 16px;
  font-weight: 400;
}
.continuewith{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.login-form h2{
  font-family: "Lexend", system-ui;
  font-size: 30px;
  font-weight: 500;
}
nav ul{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  margin-bottom: 0;
}
nav ul li{
  list-style: none;
  font-family: "Lexend", system-ui;
  font-size: 16px;
  font-weight: 300;
  color: rgba(111, 111, 111, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

}
nav h3{
  font-family: "Lexend", system-ui;
  font-size: 24px;
  font-weight: 500;
}
nav{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
padding-left: 50px;
padding-right: 50px;

}
.nav-cart{
  height: 40px;
  width: 56px;
  background-color: rgba(115, 90, 229, 1);
  border: none;
  color: white;border-radius: 4px;
  display: flex;justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Lexend", system-ui;
  font-size: 14px;
  font-weight: 400;
}
.nav-login{
  height: 40px;

  width: 96px;
  background-color: rgba(115, 90, 229, 1);
  border: none;
  color: white;border-radius: 4px;
  font-family: "Lexend", system-ui;
  font-size: 14px;
  font-weight: 400;
}
.nav-btns{
  display: flex;
  gap: 10px;
}
.banner{
  background-image: url(./Assets/bannerback.png);
  padding: 50px;
  background-size: cover;
}
.gradient-text {
  background: linear-gradient(92.52deg, #FFC465 0%, #5338FF 100%);
  font-family: "Lexend", system-ui;

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-size: 60px;
  font-weight: 700;
}
.banner p{
  font-family: "Lexend", system-ui;
  font-size: 20px;
  font-weight: 300;
}
.banner button{
padding: 20px 0px;
width: 150px;
background-color: rgba(115, 90, 229, 1);
color: white;
border: 1px solid rgba(115, 90, 229, 1);
border-radius: 10px;
}
.banner-left ul{
  display: flex;
  flex-direction: row;
  gap: 30px;
  
}
.banner-left ul li{
  list-style: none;
  font-family: "Lexend", system-ui;
  font-size: 14px;
  font-weight: 300;
}
.banner-right{
  width: 50%;
display: flex;
justify-content: center;
align-items: center;
}
.banner-left{
  width: 50%;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.banner{
  display: flex;
  flex-direction: row;
}

.marquee-container {
  width: 100%; /* Adjust as per your need */
  overflow: hidden;
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
}

.marquee {
  display: flex;
  animation: marquee 25s linear infinite;
  white-space: nowrap;
}

.marquee img {
  height: 100px;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.growthpartner{
padding: 20px 50px;
}
.growthpartner h2{
  font-family: "Lexend", system-ui;
  font-size: 1.75rem;
  font-weight: 500;

}
.growthpartner-span{
  background: linear-gradient(92.52deg, #FFC465 0%, #5338FF 100%);
  font-family: "Lexend", system-ui;
  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-weight: 500;


}
.home{
  background-image: url(./Assets/Frame\ 25.png);
  background-size: cover;
}
.count-div{
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.growthpartner-div-section h3{
  font-family: "Lexend", system-ui;
  font-size: 1.75rem;
  font-weight: 500;
}
.growthpartner-div-section p{
  font-family: "Lexend", system-ui;
  font-size: 20px;
  font-weight: 400;
}
.line{
  height: 4px;
  background: linear-gradient(90.18deg, #96FFAB 0%, #8644D8 69%, #4C1EA5 100%);
width: 220px;
margin-top: 10px;
}
.growthpartner-div-left{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* flex-wrap: wrap; */
  gap: 20px;
  padding-left: 50px;
  
  /* align-items: center; */

}
.CurrentMarket{
  background: linear-gradient(92.52deg, #FFC465 0%, #5338FF 100%);
  font-family: "Lexend", system-ui;
  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-weight: 500;
  font-size: 22px;

}
.growthpartner-div-right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;


}
.growthpartner-div-right img{
  width: 90%;
  border-radius: 20px;
}
.growthpartner-div-section{
  width: 280px;

}
.growthpartner-div{
  display: flex;
  flex-direction: row;
  padding: 50px;

}
.shipping-heading{
  /* padding-left: 50px; */
  text-align: center;
}
.shipping-heading h3{
  font-family: "Lexend", system-ui;
  font-size: 1.75rem;

  font-weight: 400;
}
.shipping-text{
  background: linear-gradient(109.67deg, #31FF69 0%, #8644D8 72%, #702FEB 100%);

  font-family: "Lexend", system-ui;
  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
}
.shipping-cards{
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  
 
  
  overflow-x: auto;
  gap: 20px;
}
.shipping-card{
  width:400px;
  background: linear-gradient(153.38deg, #F8FEFF 0%, #E2F8FF 100%);
  padding: 20px;

  
}
/* .shipping-card2{
  height: 650px;

} */
.p-text{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 30%;
}
.shipping-card img{
  width: 100%;
}
.shipping-card h3{
  font-family: "Lexend", system-ui;
  font-size: 28px;
  font-weight: 400;
  text-align: center;
}
.shipping-card p{
  font-family: "Lexend", system-ui;
  font-size: 20px;
  font-weight: 300;
  color: rgba(90, 90, 90, 1);
}
.shipping-card h4{
  font-family: "Lexend", system-ui;
  font-size: 18px;
  font-weight: 400;
  color: rgba(115, 90, 229, 1);
}
.quickdemo-div{
  padding-top: 50px;
  padding-bottom: 50px;
display: flex;
justify-content: center;
align-items: center;
}
.quickdemo{
  width: 80%;
  /* height: 66px; */
  border: 2px solid rgba(168, 225, 242, 1);
  border-radius: 8px;
  background: linear-gradient(93.67deg, #E2F8FF 0%, #F8FEFF 100%);
 display: flex;
 justify-content: center;
 align-items: center;
 gap: 30px;
 /* padding-left: 10px; */
padding: 10px;

}
.quickdemo p{
  font-family: "Lexend", system-ui;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}
.quickdemo p span{
  font-weight: 700;

}
.quickdemo button{
padding: 10px 20px;
border: 1px solid rgba(115, 90, 229, 1);
background-color: rgba(115, 90, 229, 1);
color: white;
font-family: "Lexend", system-ui;
font-size: 15px;
font-weight: 400;
border-radius: 6px;
}
.export{
display: flex;
flex-direction: row;
padding: 50px;
gap: 100px;
}
.export-left{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.export-right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.export-right img{
  width: 100%;
  border-radius: 20px;
}
.export-heading{
  padding: 50px;
}
.export-heading h3{

font-family: "Lexend", system-ui;
font-size: 1.75rem;

font-weight: 400;
}
.export-text{
  background: linear-gradient(112.68deg, #31FF69 0%, #8644D8 72%, #702FEB 100%);
  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */

}

.line2{
  background-color: rgba(198, 198, 198, 1);
  width: 100%;
  height: 2px;
}
.export-left p{
  font-family: "Lexend", system-ui;
font-size: 20px;
font-weight: 300;
color: rgba(90, 90, 90, 1);

}
.export-left h4{
  font-family: "Lexend", system-ui;
font-size:18px;
font-weight: 300;
color: rgba(115, 90, 229, 1);

}
.export-left h3{
  font-family: "Lexend", system-ui;
font-size:25px;
font-weight: 500;
color: rgba(115, 90, 229, 1);

}
.export-right{
  display: flex;
  justify-content: center;
  align-items: center;
}
.export-right img{
  width: 100%;
}
.Delight-cards{
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  overflow-x: auto;
  width: 100%;
}
.Delight-card-div{
  /* width: 30%; */
}
.Delight-card{
 width: 450px;
  background: linear-gradient(148.68deg, #FFFFFD 0%, #FFF9DD 100%);
  padding: 20px;
  border-radius: 12px;
}
.Delight-card h3{
  font-family: "Lexend", system-ui;

  font-weight: 500;
  font-size: 28px;
}
.Delight-card p{
  color: rgba(90, 90, 90, 1);
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 20px;
}
.Delight-card img{
  width: 100%;
}
.Delight-card h4{
  color: rgba(115, 90, 229, 1);
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 18px;
}
.Fulfillment{
  display: flex;
  flex-direction: row;
  padding: 0px 50px;
  gap: 50px;
}
.Fulfillment-left , .Fulfillment-right{
  width: 50%;
}
.Fulfillment-left{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Fulfillment-right{
  display: flex;
  justify-content: center;
  align-items: center;
}
.Fulfillment-right img{
  width: 100%;
}
.Fulfillment-left h3{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 18px;
  color: rgba(124, 124, 129, 1);
}
.Fulfillment-left p{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 20px;
  color: rgba(90, 90, 90, 1);
}
.Fulfillment-left h4{
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 18px;
  color: rgba(115, 90, 229, 1);
}
.Conversion-card{
  width: 300px;
  
}
.Conversion-card img{
  width: 100%;
  height: 250px;
}
.Conversion-card h3{
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 28px;
}
.Conversion-card p{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 20px;
  color: rgba(90, 90, 90, 1);
}
.Conversion-card h4{
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 24px;
  color: rgba(115, 90, 229, 1);
}
.Conversion-cards{
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 50px;
  flex-wrap: wrap;
  padding: 30px;
}
.foranywhere-left{
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;

}
.foranywhere-left h3{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 50px;
  color: white;
}
.foranywhere-left h3 span{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 50px;
  background: linear-gradient(91.85deg, #FFC465 0%, #5338FF 100%);

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
}
.foranywhere{
  background-color: rgba(71, 10, 170, 1);
  padding: 20px 50px;
  display: flex;
  

flex-direction: row;}
.foranywhere-left p{
  color: rgba(255, 255, 255, 1);
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 20px;
}
.foranywhere-left h4{
  color: rgba(255, 255, 255, 1);
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 18px;
}
.foranywhere-right{
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.foranywhere-right img{
  width: 30%;
}

.worktogether{
  padding: 50px;

}
.worktogether h2{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 50px;

}
.worktogether h2 span{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 50px;
  background: linear-gradient(95.1deg, #FFD258 0%, #8644D8 69%, #702FEB 100%);


  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
}
.worktogether h3{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 42px;
  margin-top: 10px;
  margin-bottom: 10px;

}
.worktogether p{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 20px;

}
.worktogether button{
Width:
223.92px;
Height:
62px;
background-color:rgba(115, 90, 229, 1) ;
color: white;
border-radius: 10px;
border: none;
box-shadow: 0px 24px 48px 0px rgba(68, 41, 190, 0.235);

}
.worktogether-card{
  background: linear-gradient(147.75deg, #F8FEFF 0%, #E2F8FF 100%);
  padding: 20px;
width: 412.98px;
border-radius: 12px;
}
.worktogether-card h2{
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 28px;
}
.worktogether-card p{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 20px;
  color: rgba(90, 90, 90, 1);
}
.worktogether-card h3{
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 18px;
  color: rgba(115, 90, 229, 1);
}
.worktogether-cards{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
}
.oneplace{
  padding: 50px;
}
.oneplace h2{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 50px;
}
.oneplace h2 span{
  background: linear-gradient(92.69deg, #FFC465 0%, #5338FF 100%);

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-weight: 400;
}
.oneplace p{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 20px;
  color: rgba(90, 90, 90, 1);
}
.oneplace h3{
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 18px;
  color: rgba(115, 90, 229, 1);
}
.oneplace-card{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.whatwedo{
  padding: 50px;
}
.whatwedo h2{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 50px;
}
.whatwedo h2 span{
  background: linear-gradient(92.23deg, #FFC465 0%, #5338FF 100%);
  font-weight: 400;


  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-weight: 400;
}
.whatwedo-center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 100px;
  gap: 50px;
}
.whatwedo-center p{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 28px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
}
.headlines{
  padding: 50px;

}
.headlines h2{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 50px;
}
.headlines h2 span{
  background: linear-gradient(92.71deg, #FFC465 0%, #5338FF 100%);

  font-weight: 400;


  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-weight: 400;
}
.blog-div{
  padding: 50px;
}
.blog-div h2{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 50px;
}
.blog-div h2 span{
  background: linear-gradient(92.71deg, #FFC465 0%, #5338FF 100%);

  font-weight: 400;


  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-weight: 400;
}
.blog-cards{
  display: flex;
  overflow-x: auto;
}
.blog-card{
width: 400px;
background-color: white;
border-radius: 20px;
}
.blog-card img{
  width: 100%;
}
.blog-card-text{
  padding: 20px;
}
.blog-card-text p{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 24px;
}
.blog-card-text h3{
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 18px;
  color: rgba(115, 90, 229, 1);
}
.footer{
  background-color: rgba(11, 7, 87, 1);

}
.footlogo{
  height: 100px;
}
.navlogo{
  height: 70px;
}
.top-footer{
  background-image: url(./Assets/Base.png);
  border-radius: 0px 0px 60px 60px;
  padding: 20px 100px;
  background-size: cover;
  
}
.social-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.social{
  display: flex;
  gap: 30px;
}
.line3{
  background-color: rgba(0, 0, 0, 1);
  height: 1px;
}
.footer-links h3{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 18px;
}
.footer-links ul{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  padding-left: 0;

}
.footer-links ul li{
  list-style: none;
  color: rgba(0, 0, 0, 1);
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 14px;

}
.footer-links ul li button{
  
Width:
181px;
Height:
47px;
background-color: rgba(115, 90, 229, 1);
color: white;
border-radius: 4px;
font-family: "Lexend", system-ui;
font-weight: 600;
font-size: 18px;
border: none;
}

.footer-bottom{
  display: flex;
  justify-content: center;
  align-items: center;
  

}
.footer-bottom p{
  text-align: center;
  color: white;
  font-family: "Lexend", system-ui;
font-weight: 400;
font-size: 14px;


}
.allproducts{
  background-image: url(./Assets/bannern.png);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 150px 50px;
  background-size: cover;


}

.allproducts p{
  color: rgba(0, 0, 0, 0.6);
  font-family: "Lexend", system-ui;
font-weight: 400;
font-size: 20px;
}
.allproducts p span{
  color: rgba(0, 0, 0, 1);
  font-family: "Lexend", system-ui;
font-weight: 400;
font-size: 20px;
}
.allproducts h2{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 95px;
  background: linear-gradient(92.71deg, #FFC465 0%, #5338FF 100%);

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
}
.allproducts h3{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 20px;
}
.products-div{
  padding: 50px;
}
.products-div-text{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.products-div-text h3{
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 40px;
}
.products-div-text p{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.6);

}
.products-div-text p span{
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
}
.fliter{
  height: 55px;
  border: 1px solid rgba(115, 90, 229, 0.25);
  background-color: white;
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}
.fliter p{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0;
}
.product-prise p{
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 22px;
  color: rgba(130, 130, 139, 1);

}

.product-prise{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.product-prise p del{
  color: rgba(244, 96, 54, 1);
}

.product-prise img{
  height: 50px;
  width: 50px;
}
.allpdt-btn{
  height: 56px;
  width: 200px;
  border-radius: 10px;
  background-color: rgba(115, 90, 229, 1);
  border: 1px solid rgba(115, 90, 229, 1);
  box-shadow: 0px 24px 48px 0px rgba(68, 41, 190, 0.235);
  color: white;
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 20px;

}
.center{

  display: flex;
  justify-content: center;
  align-items: center;
}
.path{
  padding: 50px;
}
.ProductDetail{
display: flex;
flex-direction: row;
padding: 50px;
gap: 20px;
}
.ProductDetail-left{
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.ProductDetail-right{
  width: 50%;
}
.side-img-div{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 20%;
}
.side-img{
  padding: 10px;
  border: 1px solid rgba(115, 90, 229, 1);
  border-radius: 6px;
  Width:100%;
}
.side-img img{
  width: 100%;
}
.main-img{
  width: 70%;
}
.main-img img{
width: 100%;
}
.ProductDetail-right h2{
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 40px;
}
.ProductDetail-right h3{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 32px;
  margin-top: 10px;
}
.ProductDetail-right h3 del{
  color: rgba(255, 51, 51, 1);
}
.ProductDetail-right p{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);

}
.ProductDetail-right h4{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);

}
.color-div{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.color{
  background-color: rgba(49, 79, 74, 1);
  border-radius: 50%;
  padding: 20px;
}
.Profile-div-heading{
  background: linear-gradient(90deg, #735AE5 0%, rgba(159, 255, 163, 0.98) 53.07%, #FFD258 100%);
height: 100px;
border-radius: 10px;

}
.Profile-div{
  border-radius: 10px;
  width: 100%;
  background-color: white;
 
}
.Profile{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}
.Profile p{
  font-family: "Lexend", system-ui;
  font-weight: 500;
  font-size: 20px;
}
.Profile-div-imgs{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Profile-div-img{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
}
.Profile-div-imgs button{
  Width:
93px;
Height:
44px;
background-color: rgba(115, 90, 229, 1);
border-radius: 8px;
color: white;
border: none;
font-family: "Lexend", system-ui;
font-weight: 400;
font-size: 16px;
}
.tabs{
  display: flex;
  gap: 50px;
  border-bottom: 1px solid rgb(186, 186, 186);
  padding-bottom: 15px;
  overflow-x: auto;
}
.tabs button{
  font-family: "Lexend", system-ui;
font-weight: 400;
font-size: 16px;
background: none;
border: none;
}
.profile-ip{
  display: flex;
  flex-direction: column;
  width: 50%;
}
.profile-ip input{
  width: 100%;
  background-color: rgba(249, 249, 249, 1);
  height: 52px;
  border-radius: 8px;
  border: none;
  padding-left: 15px;
  font-family: "Lexend", system-ui;
  font-weight: 400;
  
}
.profile-ip label{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px;

}
.profile-ips{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}
.Profile-section{
  padding: 30px;
}
.mails h3{
  font-weight: 700;
  font-size: 18px;

}
.mails h4 , .mails p{
  font-weight: 400;
  font-size: 16px;

}
.addmail{
  width: 209px;
height: 44px;

border-radius: 8px ;
background: rgba(65, 130, 249, 1);


font-size: 16px;
font-weight: 400;
color: white;
border: none;



}
.SaveChanges{
  background: rgba(115, 90, 229, 1);
  box-shadow: 0px 24px 48px 0px rgba(68, 41, 190, 0.235);
  border: 1px solid rgba(115, 90, 229, 1);

padding: 16px 35.6px 16px 36px;

border-radius: 10px;
color: white;

font-size: 20px;
font-weight: 400;


}
.btns{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Wishlist{
  padding: 50px;
}
.Wishlist h6{
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 16px;
}
.Wishlist h6 span{
  color: rgba(0, 0, 0, 1);
}
.Wishlist h2{
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-size: 50px;
}
.Wishlist-section{
  background-color: white;
  border-radius: 10px;
  padding: 30px;
}
.Wishlist-div{
  padding: 50px;
}
.cart{
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
}
.cart-left{
  width: 60%;
  padding: 20px;

}
.cart-right{
  width: 40%;
}
.cart-list{
  background-color: white;
  padding: 30px;
}
.cart-item{

}
.cart-text h3{
  font-size: 20px;
  font-weight: 500;
}
.cart-text h3{
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}
.cart-text p{
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;

}
.cart-text p span{
  color: rgba(0, 0, 0, 0.6);
}
.cart-text h5{
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;

}
.cart-text{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.quantity-input{
  background-color: rgba(240, 240, 240, 1);
  border-radius: 10px;
  width: 126px;
  height: 44px;
  display: flex;
  justify-content: space-evenly;
}
.quantity-input button , .quantity-input input{
  height: 100%;
  background: none;
  border: none;
  text-align: center;
  
}
.cart-item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cart-icon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}
.cart-prices{
  background-color: white;
  padding: 30px;
  border-radius: 20px;

}
.cart-prices h2{
  font-size: 24px;
  font-weight: 400;
}
.cart-Subtotal{
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
.cart-Subtotal h3{
  font-size: 20px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.cart-Subtotal h4{
  font-size: 20px;
  font-weight: 700;
}
.line5{
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);

}
.promocode{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.promocode button{

padding: 12px 16px 12px 16px;
border: none;
background-color: rgba(115, 90, 229, 1);
color: white;
border-radius: 10px;


}
.promocode input{
  width: 100%;
  background-color: rgba(240, 240, 240, 1);
  border-radius: 10px;
  border: none;
  padding-left: 15px;
padding: 12px 16px 12px 16px;

}

.GotoCheckout{
  border: none;
  background-color: rgba(115, 90, 229, 1);
  color: white;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  height: 60px;

}
.social-icon{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.bi-list{
  display: none;
}
@media(max-width:950px){
  .bi-list{
    display: flex;
  }
nav ul{
  display: none;
}
.nav-btns{
  display: none;

}
.profile-container{
  display: none;
}
.nav-login{
  display: none;
}
}
@media(max-width:860px){
.shipping-cards{
  padding: 0;
}
  .products-div-text{
    flex-direction: column;
  }
  .banner{
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  padding-bottom: 50px;

  }
  .banner-right ,
.banner-left{

    width: 100%;
  }
  .banner-left{
    padding-left: 0;
  }
  .banner-right img{
    width: 50%;
  }
  .growthpartner{
padding: 30px;

  }
.growthpartner h2{
  font-size: 35px;
}
.growthpartner-div{
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.growthpartner-div-left , 
.growthpartner-div-right{

  width: 100%
}
.shipping-heading{
  /* padding-left: 30px; */
}
.quickdemo{
  width: 90%;

}
.quickdemo{
  flex-direction: column;
}
.quickdemo button{
width: 100%;
}
.quickdemo img{
 
}
.export-heading{
  padding: 30px;
}
.export{
  padding: 30px;
}
.export{
  flex-direction: column;
  gap: 30px;
}
.export-left{
  width: 100%;
}
.export-right{
  width: 100%;
}
.Delight-cards{
  padding-left: 30px;
  padding-right: 30px;
}
.Delight-card{
  width: 500px;
}
.Fulfillment{
  padding: 0px 30px;
  flex-direction: column;

}
.Fulfillment-left , .Fulfillment-right{
  width: 100%;
}
.Conversion-cards{
  gap: 10px;
  justify-content: space-evenly;

}

.Conversion-card{
  width: 35%;
  
}
.foranywhere{
padding: 30px;
}
.worktogether{
  padding: 30px;

}
.worktogether h2{
  font-size: 35px;

}
.worktogether h3{
  font-size: 30px;
}
.worktogether p{
  font-size: 18px;

}
.oneplace{
  padding: 30px;
}
.oneplace h2{
  font-size: 35px;
}
.oneplace p{
  font-size: 18px;
}
.oneplace-card{
  justify-content: space-evenly;
}
.whatwedo{
  padding: 30px;
}
.whatwedo h2{
  font-size: 35px;
  text-align: center;
}

.whatwedo-center{
  padding: 50px 0px;

}
.whatwedo-center p{
  font-size: 20px;
}
.headlines{
  padding: 30px;

}
.headlines h2{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 35px;
}
.blog-div{
  padding: 30px;
}
.blog-div h2{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 35px;
}
}
@media(max-width:700px){
  .gradient-text {
    font-size: 45px;
    font-weight: 700;
}
.banner p{
  font-family: "Lexend", system-ui;
  font-size: 18px;
  font-weight: 300;
}
.banner-left ul{
  flex-wrap: wrap;
  padding-left: 0;
}
.Delight-card{
  width: 350px;
}
.Conversion-card{
  width:100%;
  margin-bottom: 20px;
  
}
.blog-card{
  width: 350px;
}
}

@media(max-width:950px){
.foranywhere{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.foranywhere-left{
  width: 100%;

}
.foranywhere-right{
  width: 100%;
}
.foranywhere-right img{
  width: 100%;
}
}
@media(max-width:600px){
.social-div{
  flex-direction: column;
  gap: 30px;
}
.top-footer{
  padding: 50px 30px;

}

}
.dash{
  padding: 30px;
}
.heading{
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
}
.heading h1{
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.heading button{
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(115, 90, 229, 1);
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
}
.dash-cards{
display: flex;
flex-direction: row;
justify-content: center;
gap: 30px;
flex-wrap: wrap;
}
.dash-card{
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  width: 280px;
}
.dash-card-heading{
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.dash-card-heading p{
  font-size: 16px;
  font-weight: 600;
}
.dash-card-text{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dash-card-text-left{
  width: 50%;
}
.dash-card-text-right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-selector-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-container {
  margin-bottom: 20px;
}

.preview img {
  width: 300px;
  height: auto;
  border: 2px solid #000;
}

/* Hide default radio buttons */
input[type="radio"] {
  display: none;
}

/* Style the labels as buttons */
.radio-btn {
  width: 45%;
  display: inline-block;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
  border: 2px solid rgba(115, 90, 229, 1);
  border-radius: 5px;
  background-color: white;
  color: rgba(115, 90, 229, 1);
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
}

/* Change appearance when selected */
.radio-btn.active {
  background-color: rgba(115, 90, 229, 1);
  color: white;
}

/* Hover effect for all buttons */
.radio-btn:hover {
  background-color: rgba(115, 90, 229, 1);
  color: white;
}
.add-heading{
  background-color: white;
  padding: 20px;
display: flex;
align-items: center;
gap: 20px;
border-radius: 16px;
}
.add-heading h3{
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}
.home-padding{
  /* padding: 30px; */
}
.home-board-padding{
  padding: 0px 50px;
}
.home-board-padding2{
  padding: 0px 50px;
}
.allproduct-color{
  background-color: white;
  padding: 20px;

}
.allproduct-inps{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 15px;
 overflow-x: auto;
}
.allproduct-inps select{
  background-color: rgba(249, 249, 249, 1);
  border: 1px solid rgba(226, 225, 225, 1);
  border-radius: 8px;
  height: 37px;
  width: 188px;
  padding-left: 10px;
  color: gray;
}
.allproduct-inps button{
  background-color: rgba(115, 90, 229, 1);
  color: white;
  border-radius: 8px;
  Width:
91px;
Height:
37px;
border: none;
}
.newover{
  overflow-x: auto !important;
}
.table-headings th{
  color: rgba(115, 90, 229, 1) !important;
  font-size: 14px;
  font-weight: 500;
}

tbody td{
  font-size: 14px;
  font-weight: 300;
  color: rgba(117, 109, 120, 1);
}
.orderstatus-btn{
  background: rgba(115, 90, 229, 1);
  width: 103px;
  height: 40px;
color: white;
border: none;
  border-radius: 8px;
 
}

.toggleDiv {
  /* width: 100%; */
  height: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color:rgba(19, 11, 70, 0.25);
  border: 1px solid #787878;
  border-radius: 50px;
  color: rgb(255, 255, 255);
  text-align: center;
margin-top: 150px;
padding-top: 50px;

}
.toggleDiv ul{
  padding-left: 0px;
}
.toggleDiv ul li{
  font-family: "Manrope", sans-serif;
  /* color: rgb(255, 255, 255); */

  font-weight: 700;
  font-size: 16px;
  list-style: none;
  margin-bottom: 25px;
}
.toggleDiv-ops{
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
z-index: 100;
  top: 80px;
}
/* Navbar.css */

/* Keyframes for the slide-in animation */
@keyframes slideUp {
from {
  transform: translateY(100%);
  opacity: 0;
}
to {
  transform: translateY(0);
  opacity: 1;
}
}

/* Style for the toggle div */
.toggleDiv {

animation: slideUp 0.5s ease-out; /* Apply the slide-up animation */
z-index: 1000; /* Ensure it's on top */
}

.toggleDiv {
padding: 20px; /* Padding for content */
}

.none{
  list-style: none;
  text-decoration: none;
  color: white;
}

@media(max-width:600px){


.products-div{
padding: 30px;
}
}


@media(max-width:768px){



.products-div-text p{
  font-family: "Lexend", system-ui;
  font-weight: 400;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.6);

}

.allproducts h2{
  font-size: 35px;

}
.allproducts{
  padding: 50px 30px;

}
.allproducts p{
  color: rgba(0, 0, 0, 0.6);
  font-family: "Lexend", system-ui;
font-weight: 400;
font-size: 15px;
}
.allproducts h3{
font-size: 15px;

}
.allproducts p span{
  color: rgba(0, 0, 0, 1);
  font-family: "Lexend", system-ui;
font-weight: 400;
font-size: 15px;
}
.shipping-card{
  width:300px;
}
.fliter p{
  font-family: "Lexend", system-ui;
  font-weight: 700;
  font-size: 15px;
}
.products-div-text h3{
  font-size: 30px;

}
.products-div-text p{
  font-size: 18px;

}
.shipping-card h3{
  font-family: "Lexend", system-ui;
  font-size: 20px;
  font-weight: 400;
}
.shipping-card p{
  font-family: "Lexend", system-ui;
  font-size: 15px;
  font-weight: 300;
  color: rgba(90, 90, 90, 1);
}
.shipping-card h4{
  font-family: "Lexend", system-ui;
  font-size: 15px;
  font-weight: 400;
  color: rgba(115, 90, 229, 1);
}
}

@media(max-width:870px){
  .ProductDetail{
    display: flex;
  flex-direction: column;  }
  .ProductDetail-left{
    width: 100%;
  }
  .ProductDetail-right{
    width: 100%;
  }
}
@media(max-width:768px){
.ProductDetail{
padding: 30px;

}
.ProductDetail-right h2{
  font-size: 30px;
}
.ProductDetail-right h3{
  font-size: 20px;
}
.path{
  padding: 20px;
}
.Profile{
  padding: 20px;
}
.Wishlist-div , .Wishlist{
  padding: 20px;
}
.Wishlist h2{
  color: rgba(0, 0, 0, 1);
  font-weight: 700;
  font-size: 35px;
}
.Wishlist h6{
  font-size: 12px;
}
.home-padding{
  padding: 5px;
}
}

@media(max-width:600px){
  .profile-ips{
    flex-direction: column;
  }
.profile-ip{
  width: 100%;
}
.btns{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.addmail , .SaveChanges{
  width: 100%;
}
.Profile-div-imgs{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 15px;
}
}
@media(max-width:950px){
  .cart{
    flex-direction: column;
  }
  .cart-left{
    width: 100%;
    padding: 20px;
  
  }
  .cart-right{
    width: 100%;
  }
}
@media(max-width: 550px){

  .cart-item{
    flex-direction: column;
  }
  .cart-text{
    flex-direction: column;
  }
  .cart-icon{
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .cart{
    padding: 0px;
  }
  .cart-text h3{
    font-size: 20px;
    font-weight: 500;
  }
  .cart-text h3{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .cart-text p{
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
  
  }
  .cart-text p span{
    color: rgba(0, 0, 0, 0.6);
  }
  .cart-text h5{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
  
  }
}
@media(max-width: 650px){
  .dash-card{
    width: 100%;
  }

}

.addmain-left{
  width: 60%;

  display: flex;
  flex-direction: column;
}
.addmain-left h3{
  font-size: 14px;
  font-weight: 500;
  color: rgba(115, 90, 229, 1);
}


.addmain-left p{
  color: rgba(141, 141, 153, 1);
  font-size: 12px;
  font-weight: 400;
}
.add-selector{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.addmain-ips{
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}
.addmain-ips select{
  width: 200px;
  border: 1px solid rgba(226, 225, 225, 1);
  background: rgba(249, 249, 249, 1);
  color: rgba(104, 115, 121, 1);
  border-radius: 8px;
  height: 37px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  
}
.addmain-ips label{
  color: rgba(141, 141, 153, 1);
  font-size: 12px;
  font-weight: 500;
}
.addmain-ips textarea{
 
  border: 1px solid rgba(226, 225, 225, 1);
  background: rgba(249, 249, 249, 1);
  color: rgba(104, 115, 121, 1);
  border-radius: 8px;
  height: 37px;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  padding-top: 10px;
  
}


.add-main{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.add-right-ips{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.add-right-ip{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.add-right-ip label{
  color: rgba(141, 141, 153, 1);
  font-size: 12px;
  font-weight: 500;
}
.add-right-ip input{
  border: 1px solid rgba(226, 225, 225, 1);
  background: rgba(249, 249, 249, 1);
  border-radius: 8px;
  height: 37px;
  padding-left: 15px;
  width: 100%;

}
.addmain-right{
  width: 40%;
}
.SaveToDraft{
  box-shadow: 0px 24px 48px 0px rgba(68, 41, 190, 0.235);
  border: 1px solid rgba(115, 90, 229, 1);

padding: 16px 48.68px 16px 49px;

border-radius: 10px;

font-size: 20px;
font-weight: 400;
color: rgba(115, 90, 229, 1);
background-color: white;


}
.PublishProduct{
  background: rgba(115, 90, 229, 1);
  border: 1px solid rgba(115, 90, 229, 1);
  box-shadow: 0px 24px 48px 0px rgba(68, 41, 190, 0.235);

  padding: 16px 46px 16px 46px;
border-radius: 10px;
color: white;

font-size: 20px;
font-weight: 400;


  
}
.add-btns-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media(max-width: 1150px){
  .add-main{
    flex-direction: column;
    gap: 20px;
  }
  .addmain-left , .addmain-right{
    width: 100%;
  }
}

@media(max-width: 700px){
  .addmain-ips{
    flex-direction: column;
  }
.addmain-ips select{
 width: 100%; 
}
.add-selector{

}
}
@media(max-width: 600px){
.add-right-ips{
  flex-direction: column;
}
.SaveToDraft{
  box-shadow: 0px 24px 48px 0px rgba(68, 41, 190, 0.235);
  border: 1px solid rgba(115, 90, 229, 1);

padding: 10px 20px 10px 20px;

border-radius: 10px;

font-size: 15px;
font-weight: 400;
color: rgba(115, 90, 229, 1);
background-color: white;


}
.PublishProduct{
  background: rgba(115, 90, 229, 1);
  border: 1px solid rgba(115, 90, 229, 1);
  box-shadow: 0px 24px 48px 0px rgba(68, 41, 190, 0.235);

  padding: 10px 20px 10px 20px;

border-radius: 10px;
color: white;

font-size: 15px;
font-weight: 400;


  
}
}
.none2{
  text-decoration: none;
}
@media(max-width: 1100px){
.login-form{
  width: 100%;
  padding: 30px;
}
}
@media(max-width: 900px){
  .login-banner{
    flex-direction: column-reverse;
  }
  .login-banner-left{
    width: 100%;
  }
  .login-banner-right{
    width: 100%;
  }
.login-banner-left img{
  height: 200px;

}
.login-banner-left h2{
  font-family: "Lexend", system-ui;
  font-size: 35px;
}
.login-banner-left h3{
  font-size: 25px;
}
.login-banner-left p{
  font-size: 12px;
}
}

@media(max-width: 700px){

.login-banner{
  padding: 20px;

}
.login-banner-vec{
  flex-direction: column;
  align-items: start;
}
.role-selector{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.radio-btn {
  width:100%;
  margin: 0 0px;

}
}


.active {
  color: #ffffff !important;
  font-weight: bold;
  border-bottom: 2px solid #007bff;
  
}

.banner-btn{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.getgift{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
 
}
.getgift h1{
  background: linear-gradient(92.52deg, #FFC465 0%, #5338FF 100%);
  font-family: "Lexend", system-ui;

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-size: 40px;
  font-weight: 700;
}
.privacy{
  padding: 50px;
}
.privacy h1{
  background: linear-gradient(92.52deg, #FFC465 0%, #5338FF 100%);
  font-family: "Lexend", system-ui;

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-size: 60px;
  font-weight: 700;
  text-align: center;
}
.privacy h2{
  background: linear-gradient(92.52deg, #FFC465 0%, #5338FF 100%);
  font-family: "Lexend", system-ui;

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-size: 30px;
  font-weight: 500;
 
}
@media(max-width: 768px){
  .privacy{
    padding: 20px;
  }
  .privacy h1{

  font-size: 30px;

}
.getgift h1{
  font-size: 25px;
}

}
.soft-products-div{
  padding: 50px;
}
.soft-products-div p{
  margin-bottom: 0;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  margin-left: -200px;
  border-radius: 15px;
 
}
.dropdown-content2 {
  margin-left: 0px !important;
  min-width: 120px;


}
.dropdown-content2 li{
  margin-bottom: 10px;
}

.dropdown:hover .dropdown-content {
  display: block;

}
.dp-name{
  display: flex;
  flex-direction: column;
}
.dp-name button{
  border: none;
  padding: 12px 16px 12px 16px;
  border: none;
  background-color: rgba(115, 90, 229, 1);
  color: white;
  border-radius: 10px;
  margin-top: 10px;  
}
@media(max-width: 1230px){
  .growthpartner-div-right{
    width: 40%;
  }
  .growthpartner-div-left{
    width: 60%;
  }
}
@media(max-width: 1050px){
.count-div{
  gap: 20px;
}
.growthpartner-div-left{
  width: 100%;
  justify-content: start;
  align-items: start;

  padding-left: 0;
}
.growthpartner-div{
  flex-direction: column;
  gap: 30px;
}
.growthpartner-div-right {
  width: 100%;
  justify-content: start;
  align-items: start;
}
.growthpartner-div-right img{
  width: 100%;
}


}
@media(max-width: 640px){
  .count-div{
    flex-direction: column;
  }
}
@media(max-width:500px){
  /* .banner-btn{
    flex-direction: column;
  } */
}
@media(max-width:1220px){

  
  .shipping-cards{
    justify-content: start;
  }
}
.des h3{
  float: inline-end !important;
  background: linear-gradient(92.52deg, #FFC465 0%, #5338FF 100%);
  font-family: "Lexend", system-ui;

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  margin-top: -50px;
 
}
.des h3 span{
  font-size: 15px;
  float: inline-end !important;

}
.des{
  padding-right: 120px !important;
}

ul {
  list-style-type: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-menu li {
  padding: 12px 16px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.about{
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about h1{
  text-align: center;
  background: linear-gradient(92.52deg, #FFC465 0%, #5338FF 100%);
  font-family: "Lexend", system-ui;

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-size: 60px;
  font-weight: 700;
}
.about-div{
  border: 1px solid #aca3e1;
  background-color: white;
  width: 90%;
  padding: 50px;
  border-radius: 20px;

}

.about-div h2{
  background: linear-gradient(109.67deg, #8644D8 72%, #702FEB 100%);


  font-family: "Lexend", system-ui;

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */

}
@media(max-width:768px){
  .about{
    padding: 10px;
  }
.about h1{
  font-size: 35px;
}
.about-div{
padding: 30px;
}
}
.comingsoon{
  height: 80vh;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(./Assets/Coming_soon_gif_2048x2048.webp);
  background-color: #EFEFEF;
}

@media(max-width:1150px){
  .des h3{
    margin-top: -40px;

  }
}
@media(max-width:800px){
  .des{
    padding-right: 20px !important;
  }
}
@media(max-width:500px){
  .des h3{
    font-size: 20px;
  }
}
@media(max-width:768px){
  .shipping-card2{
  height: 500px;

}
}

.add-to-cart-btn , .add-to-wishlist-btn{
  background-color: rgba(115, 90, 229, 1);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.add-cart-btns{
  display: flex;
  flex-direction: row;
  gap: 15px;
}
@media(max-width:500px){
  .add-cart-btns{
    display: flex;
    flex-direction: column;
  }
}
.home2{
  background-image: url(./Assets/Frame\ 25.png);
  background-size: cover;
  padding: 50px;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;

}
.home2 h1{
  background: linear-gradient(92.52deg, #FFC465 0%, #5338FF 100%);
  font-family: "Lexend", system-ui;

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-size: 100px;
  font-weight: 700;
  text-align: center;

}
.growthpartner-lines{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.noworry{
  padding: 50px;
  display: flex;
  flex-direction: row;

}
.noworry-left{
  width: 50%;
}
.noworry-right{
  width: 50%;
}
.noworry-left h1{
  font-size: 40px;
  font-weight: 700;
}
.noworry-left h1 span{
  background: linear-gradient(92.52deg, #FFC465 0%, #5338FF 100%);
  font-family: "Lexend", system-ui;

  background-clip: text;
  -webkit-background-clip: text; /* For Safari and Chrome */
  color: transparent; /* To make sure only the gradient is shown */
  -webkit-text-fill-color: transparent; /* For Safari */
  font-size: 40px;
  font-weight: 700;

}
.noworry-left p{
  font-family: "Lexend", system-ui;
  font-size: 20px;
  font-weight: 300;
}
.noworry-btns{
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.noworry-btns-1{
  padding: 10px 25px;
  border-radius: 10px;
  border: 1px solid rgba(115, 90, 229, 1);
  background-color: rgba(115, 90, 229, 1);
  color: white;
}
.noworry-btns-2{
  background: none;
  border: none;
}
.button-group button , .toggle-buttons button , .text-center2 button{
  padding: 10px 25px;
}
.publishproduct , .savedrf{
  padding: 10px 25px;
  border-radius: 10px;
  border: 1px solid rgba(115, 90, 229, 1);


}
.publishproduct {
  background-color: rgba(115, 90, 229, 1);
color: white;
}
.savedrf{
  background-color: white;
  color: rgba(115, 90, 229, 1);
}
.home-padding11{
  min-height: 70vh;
}
.navlogo2{
  position: absolute;
  top: 0;
}
.noworry-left{

}